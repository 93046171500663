import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/fr'

dayjs.extend(localizedFormat)

const dayjsMap: any = {
  fr: 'fr',
}

export const setDayjsLocale = (lng: string) => {
  dayjs.locale(dayjsMap?.[lng] || dayjsMap.en)
}
