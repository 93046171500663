import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import type { AdProps } from '../../components/molecules/Ad';
import type { FooterProps } from '../../components/molecules/Footer';
import type { HeaderProps } from '../../components/molecules/Header';
import type { ProductPopinProps } from '../../components/molecules/ProductPopin';
import type { BreadcrumbProps } from '../../components/molecules/Breadcrumb';
import type { LightAuthLoginModalProps } from '../../components/molecules/LightAuthModal/LightAuthLoginModal';
import type { LightAuthRegisterModalProps } from '../../components/molecules/LightAuthModal/LightAuthRegisterModal';
import type { LightAuthOptinModalProps } from '../../components/molecules/LightAuthModal/LightAuthOptinModal';
import type { AuthPopinProps } from '../../components/molecules/AuthPopin';
import * as SC from './styled';
export type MainLayoutProps = {
  className?: string;
  idContent?: string;
  breadcrumb?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  headerProps?: HeaderProps;
  footerProps?: FooterProps;
  adProps?: AdProps;
  children?: React.ReactNode;
  productPopinProps?: ProductPopinProps;
  withMobileModal?: boolean;
  breadcrumbProps?: BreadcrumbProps;
  classicAuthModalProps?: AuthPopinProps;
  lightAuthLoginModalProps?: LightAuthLoginModalProps;
  lightAuthRegisterModalProps?: LightAuthRegisterModalProps;
  lightAuthOptinModalProps?: LightAuthOptinModalProps;
  modals?: React.ReactNode;
};
const MainLayout: FC<MainLayoutProps> = ({
  className,
  idContent = 'Content',
  header,
  footer,
  breadcrumb,
  adProps,
  children,
  productPopinProps,
  withMobileModal,
  breadcrumbProps,
  classicAuthModalProps,
  lightAuthLoginModalProps,
  lightAuthRegisterModalProps,
  lightAuthOptinModalProps,
  modals
}) => {
  const ProductPopin = productPopinProps?.popin?.isOpen && dynamic(() => import('../../components/molecules/ProductPopin'));
  const AuthPopin = classicAuthModalProps?.isOpen && dynamic(() => import('../../components/molecules/AuthPopin'));
  const LightAuthLoginModal = lightAuthLoginModalProps?.isOpen && dynamic(() => import('../../components/molecules/LightAuthModal/LightAuthLoginModal'));
  const LightAuthRegisterModal = lightAuthRegisterModalProps?.isOpen && dynamic(() => import('../../components/molecules/LightAuthModal/LightAuthRegisterModal'));
  const LightAuthOptinModal = lightAuthOptinModalProps?.isOpen && dynamic(() => import('../../components/molecules/LightAuthModal/LightAuthOptinModal'));
  return <>
      <SC.Layout className={className} $adBackground={adProps?.background}>
        {header}
        {/*headerProps && <Header {...headerProps} />*/}
        {adProps && <SC.StyledAd {...adProps} />}
        <SC.Main id={idContent} $withMobileModal={withMobileModal} $withAd={!!adProps && adProps.isBoxed}>
          {breadcrumb}
          {breadcrumbProps?.entries && breadcrumbProps?.entries.length > 0 && <SC.Breadcrumb {...breadcrumbProps} />}
          <SC.Content>{children}</SC.Content>
        </SC.Main>
        {footer}
        {/*footerProps && <Footer {...footerProps} />*/}
      </SC.Layout>
      {modals}
      {productPopinProps && ProductPopin && <ProductPopin {...productPopinProps} />}
      {classicAuthModalProps && AuthPopin && <AuthPopin {...classicAuthModalProps} />}
      {lightAuthLoginModalProps && LightAuthLoginModal && <LightAuthLoginModal {...lightAuthLoginModalProps} />}
      {lightAuthRegisterModalProps && LightAuthRegisterModal && <LightAuthRegisterModal {...lightAuthRegisterModalProps} />}
      {lightAuthOptinModalProps && LightAuthOptinModal && <LightAuthOptinModal {...lightAuthOptinModalProps} />}
    </>;
};
export default MainLayout;